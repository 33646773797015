import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const getOrders = createAsyncThunk(
  "mainSlice/getOrders",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url)
      if (response.status === 200 || response.status === 201) {
        console.log(response.data)
        return dispatch(set_orders(response.data))
      }
      if (!response.status) {
        throw new Error("Internal Server Error")
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


export const getOrderDetails = createAsyncThunk(
  "mainSlice/getOrderDetails",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url)
      if (response.status === 200 || response.status === 201) {
        return dispatch(set_order_details(response.data))
      }
      if (!response.status) {
        throw new Error("Internal Server Error")
      }

    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)







export const main = createSlice({
  name: "mainSlice",
  initialState: {
    orders_all: {
      data: [],
      error: null,
      status: null
    },
    order_details: {
      data: [],
      error: null,
      status: null
    },

  },
  reducers: {
    set_orders: (state, action) => {
      state.orders_all.data = action.payload
    },
    set_order_details: (state, action) => {
      state.order_details.data = action.payload
    },
  },
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.orders_all.status = 'pending'
      state.orders_all.error = null
    },
    [getOrders.fulfilled]: (state, action) => {
      state.orders_all.status = "resolved"
    },
    [getOrders.rejected]: (state, action) => {
      state.orders_all.status = "rejected";
      state.orders_all.error = action.payload;
    },
    [getOrderDetails.pending]: (state) => {
      state.order_details.status = 'pending'
      state.order_details.error = null
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.order_details.status = "resolved"
    },
    [getOrderDetails.rejected]: (state, action) => {
      state.order_details.status = "rejected";
      state.order_details.error = action.payload;
    },
  }
})

export const { set_orders, set_order_details } = main.actions

export default main.reducer


