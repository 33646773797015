import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_director_residueStorage = createAsyncThunk(
    "residue_storage/get_director_residueStorage",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_director_residueStorage(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)




export const residue = createSlice({
    name:"residueSlice",
    initialState:{
        residue_storage:{
            data:[],
            status:null,
            error:null,
            count:1
        },
    },
    reducers:{
        set_director_residueStorage:(state, action)=>{
            state.residue_storage.data = action.payload.results
            state.residue_storage.count = action.payload.count

        },
    
    },
    extraReducers:{
        [get_director_residueStorage.pending]:(state)=>{
           state.residue_storage.status = 'pending'
           state.residue_storage.error = null
        },
        [get_director_residueStorage.fulfilled]:(state,action)=>{
           state.residue_storage.status = "resolved"
        }, 
        [get_director_residueStorage.rejected]:(state,action)=>{
          state.residue_storage.status = "rejected";
          state.residue_storage.error = action.payload;
        }
    }
})

export const {set_director_residueStorage}  = residue.actions

export default residue.reducer


