import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_director_incomeList = createAsyncThunk(
    "incomeSlice/get_director_incomeList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_director_incomeList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)




export const income = createSlice({
    name:"incomeSlice",
    initialState:{
        income_list:{
            data:[],
            status:null,
            error:null
        },
    },
    reducers:{
        set_director_incomeList:(state, action)=>{
            state.income_list.data = action.payload
        },
    
    },
    extraReducers:{
        [get_director_incomeList.pending]:(state)=>{
           state.income_list.status = 'pending'
           state.income_list.error = null
        },
        [get_director_incomeList.fulfilled]:(state,action)=>{
           state.income_list.status = "resolved"
        }, 
        [get_director_incomeList.rejected]:(state,action)=>{
          state.income_list.status = "rejected";
          state.income_list.error = action.payload;
        }
    }
})

export const {set_director_incomeList}  = income.actions

export default income.reducer


