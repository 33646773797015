import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const get_director_dashboard = createAsyncThunk(
    "dashboardSlice/get_director_dashboard",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                return dispatch(set_dashboard_info(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)

export const getAllWindowOrders = createAsyncThunk(
    "dashboardSlice/getAllWindowOrders",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                console.log(response);
                return dispatch(set_dashboard(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)


export const dashboard = createSlice({
    name: "dashboardSlice",
    initialState: {
        dashboard_info: {
            overall_sum: 0,
            clients_count: 0,
            orders_paid_count: 0,
            paid_money: 0,
            need_paid: 0,
            overall_client_count: 0,
            order_client_count: 0,
            income_sum: 0,
            expenses_sum: 0,
            benefit_sum: 0,
            profil_count: 0,
            accessory_count: 0,
            window_count: 0,
            status: null,
            error: null
        },
        showroom_window: {
            data: [],
            status: null,
            count: 1,
            error: null
        },

    },
    reducers: {
        set_dashboard_info: (state, action) => {
            state.dashboard_info.overall_sum = action.payload.overall_sum
            state.dashboard_info.clients_count = action.payload.clients_count
            state.dashboard_info.orders_paid_count = action.payload.orders_paid_count
            state.dashboard_info.paid_money = action.payload.paid_money
            state.dashboard_info.need_paid = action.payload.need_paid
            state.dashboard_info.overall_client_count = action.payload.overall_client_count
            state.dashboard_info.order_client_count = action.payload.order_client_count
            state.dashboard_info.income_sum = action.payload.income_sum
            state.dashboard_info.expenses_sum = action.payload.expenses_sum
            state.dashboard_info.benefit_sum = action.payload.benefit_sum
            state.dashboard_info.profil_count = action.payload.profil_count
            state.dashboard_info.accessory_count = action.payload.accessory_count
            state.dashboard_info.window_count = action.payload.window_count
        },
        set_dashboard: (state, action) => {
            state.showroom_window.data = action.payload.data.orders
            state.showroom_window.count = action.payload.count

        },
    },
    extraReducers: {
        [get_director_dashboard.pending]: (state) => {
            state.dashboard_info.status = 'pending'
            state.dashboard_info.error = null
        },
        [get_director_dashboard.fulfilled]: (state, action) => {
            state.dashboard_info.status = "resolved"
        },
        [get_director_dashboard.rejected]: (state, action) => {
            state.dashboard_info.status = "rejected";
            state.dashboard_info.error = action.payload;
        },
    }
})

const { set_dashboard_info,set_dashboard } = dashboard.actions

export default dashboard.reducer