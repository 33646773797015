import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";
import axios from "axios";

export const get_residues = createAsyncThunk(
    "residueSlice/get_residues",
     async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_residues(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        } 
     }
)



export const get_residues_acceptance = createAsyncThunk(
    "residueSlice/get_residues_acceptance",
     async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_residues_acceptance(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        } 
     }
)

export const get_residues_acceptance_detail = createAsyncThunk(
    "residueSlice/get_residues_acceptance_detail",
     async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_residues_acceptance_detail(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        } 
     }
)


export const warehouse_residues = createSlice({
    name:"residueSlice",
    initialState:{
        residues:{
            data:[],
            status:null,
            error:null
        },
        residues_acceptance:{
            data:[],
            status:null,
            error:null
        },
        residues_acceptance_detail:{
            data:{},
            status:null,
            error:null
        }
    },
    reducers:{
        set_residues:(state,action)=>{
            state.residues.data = action.payload
        },
        set_residues_acceptance:(state,action)=>{
            state.residues_acceptance.data = action.payload
        },
        set_residues_acceptance_detail:(state,action)=>{
            state.residues_acceptance_detail.data = action.payload
        },
        
    },
    extraReducers:{
        [get_residues.pending]:(state)=>{
            state.residues.status = 'pending'
            state.residues.error = null
        },
        [get_residues.fulfilled]:(state,action)=>{
            state.residues.status = "resolved"
        }, 
        [get_residues.rejected]:(state,action)=>{
           state.residues.status = "rejected";
           state.residues.error = action.payload;
        },
        [get_residues_acceptance.pending]:(state)=>{
            state.residues_acceptance.status = 'pending'
            state.residues_acceptance.error = null
        },
        [get_residues_acceptance.fulfilled]:(state,action)=>{
            state.residues_acceptance.status = "resolved"
        }, 
        [get_residues_acceptance.rejected]:(state,action)=>{
           state.residues_acceptance.status = "rejected";
           state.residues_acceptance.error = action.payload;
        },
        [get_residues_acceptance_detail.pending]:(state)=>{
            state.residues_acceptance_detail.status = 'pending'
            state.residues_acceptance_detail.error = null
        },
        [get_residues_acceptance_detail.fulfilled]:(state,action)=>{
            state.residues_acceptance_detail.status = "resolved"
        }, 
        [get_residues_acceptance_detail.rejected]:(state,action)=>{
           state.residues_acceptance_detail.status = "rejected";
           state.residues_acceptance_detail.error = action.payload;
        }
    }
})

export const {
    set_residues,
    set_residues_acceptance,
    set_residues_acceptance_detail
}  = warehouse_residues.actions

export default warehouse_residues.reducer









// {
//     item?.profil.map(nestedItem=>{
//      return (<>
//       <td key={nestedItem.id}>{nestedItem.title}</td>
//       <td>{nestedItem.articul}</td>
//       <td>{nestedItem.color}</td>
//       <td className = "text-center"><span style = {{color:"#29FF06"}} className = "mx-1">{nestedItem.condition} </span><span className = "text-danger mx-1">{nestedItem.not_condition}</span></td>
//       <td>{nestedItem.total_price}</td>
//       <td>{nestedItem.count}</td>
//       <td>{nestedItem}</td>
//       </>)
//     })
//   }