import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const get_payment_orders = createAsyncThunk(
    "payment/get_payment_orders",
    async function (_, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get("/sales/payment/orders/")
            if (response.status === 200 || response.status === 201) {
                dispatch(set_payment_orders(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const change_payment_data = createAsyncThunk(
    "payment/change_payment_data",
    async function ({ data, id }, { rejectWithValue, dispatch }) {
        try {
            let response = await api.put(`/show_room/payment/${id}/`, data)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)





export const get_payment_list = createAsyncThunk(
    "payment/get_payment_list",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            console.log(response.data);
            if (response.status === 200 || response.status === 201) {
                dispatch(set_payments_list(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const get_single_payment = createAsyncThunk(
    "payment/get_single_payment",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                return dispatch(set_single_payment(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const post_payment_orders = createAsyncThunk(
    "payment/post_payment_orders",
    async function (data, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("/show_room/payment/", data)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const payment = createSlice({
    name: "paymentSlice",
    initialState: {
        payment_orders: {
            orders: [],
            status: null,
            error: null
        },
        push_payment: {
            status: null,
            error: null
        },
        payments_list: {
            data: [],
            count: 1,
            status: null,
            error: null
        },
        payment_change: {
            status: null,
            error: null
        },
        single_payment: {
            data: null,
            status: null,
            error: null
        }
    },
    reducers: {
        set_payment_orders: (state, action) => {
            state.payment_orders.orders = action.payload
        },
        set_payments_list: (state, action) => {
            console.log(action);
            state.payments_list.data = action.payload
            state.payments_list.count = action.payload.count
        },
        clear_single_payment: (state, action) => {
            state.single_payment.data = null
        },
        set_single_payment: (state, action) => {
            state.single_payment.data = action.payload
        }
    },
    extraReducers: {
        [get_payment_orders.pending]: (state) => {
            state.payment_orders.status = 'pending'
            state.payment_orders.error = null
        },
        [get_payment_orders.fulfilled]: (state, action) => {
            state.payment_orders.status = "resolved"
        },
        [get_payment_orders.rejected]: (state, action) => {
            state.payment_orders.status = "rejected";
            state.payment_orders.error = action.payload;
        },
        [post_payment_orders.pending]: (state) => {
            state.push_payment.status = 'pending'
            state.push_payment.error = null
        },
        [post_payment_orders.fulfilled]: (state, action) => {
            state.push_payment.status = "resolved"
        },
        [post_payment_orders.rejected]: (state, action) => {
            state.push_payment.status = "rejected";
            state.push_payment.error = action.payload;
        },
        [get_payment_list.pending]: (state) => {
            state.payments_list.status = 'pending'
            state.payments_list.error = null
        },
        [get_payment_list.fulfilled]: (state, action) => {
            state.payments_list.status = "resolved"
        },
        [get_payment_list.rejected]: (state, action) => {
            state.payments_list.status = "rejected";
            state.payments_list.error = action.payload;
        },
        [change_payment_data.pending]: (state) => {
            state.payment_change.status = 'pending'
            state.payment_change.error = null
        },
        [change_payment_data.fulfilled]: (state, action) => {
            state.payment_change.status = "resolved"
        },
        [change_payment_data.rejected]: (state, action) => {
            state.payment_change.status = "rejected";
            state.payment_change.error = action.payload;
        },
        [get_single_payment.pending]: (state) => {
            state.single_payment.status = 'pending'
            state.single_payment.error = null
        },
        [get_single_payment.fulfilled]: (state, action) => {
            state.single_payment.status = "resolved"
        },
        [get_single_payment.rejected]: (state, action) => {
            state.single_payment.status = "rejected";
            state.single_payment.error = action.payload;
        }

    }
})

export const { set_payment_orders, set_payments_list, set_single_payment, clear_single_payment } = payment.actions

export default payment.reducer
