import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";


export const postExpense = createAsyncThunk(
    "expenseSlice/postExpense",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getExpenses = createAsyncThunk(
    "expenseSlice/getExpenses",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(setExpenseList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const expense = createSlice({
    name:"expenseSlice",
    initialState:{
        newExpense:{
          status:null,
          error:null,
        },
        expenseList:{
            data:[],
            count:null,
            status:null,
            error:null
        },
    },
    reducers:{
        setExpenseList:(state, action)=>{
            state.expenseList.data = action.payload.results
            state.expenseList.count = action.payload.count
        },
    },
    extraReducers:{
        [postExpense.pending]:(state)=>{
            state.newExpense.status = 'pending'
            state.newExpense.error = null
        },
        [postExpense.fulfilled]:(state,action)=>{
            state.newExpense.status = "resolved"
        }, 
        [postExpense.rejected]:(state,action)=>{
           state.newExpense.status = "rejected";
           state.newExpense.error = action.payload;
        },

        [getExpenses.pending]:(state)=>{
           state.expenseList.status = 'pending'
           state.expenseList.error = null
        },
        [getExpenses.fulfilled]:(state,action)=>{
           state.expenseList.status = "resolved"
        }, 
        [getExpenses.rejected]:(state,action)=>{
          state.expenseList.status = "rejected";
          state.expenseList.error = action.payload;
        },
    }
})

export const {setExpenseList}  = expense.actions

export default expense.reducer


