import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";


export const postOrder = createAsyncThunk(
    "orderSlice/postOrder",
    async function (postNewOrder, { rejectWithValue, dispatch }) {
        console.log(postNewOrder);
        try {
            let response = await api.post("/order/order/", postNewOrder)
            if (response.status === 200 || response.status === 201) {
                console.log(response);
                return { postNewOrder }
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const cancelOrder = createAsyncThunk(
    "orderSlice/cancelOrder",
    async function (data, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("/order/order/canceled/", data)
            if (response.status === 200 || response.status === 201) {
                return { data }
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const sendOrderFiles = createAsyncThunk(
    "orderSlice/postOrder",
    async function (order, { rejectWithValue, dispatch }) {
        console.log('ishladi 1');
        try {
            console.log('ishladi 2');
            let response = await api.patch(`/order/order/${order.id}/`, order.detail)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const changeSingleOrder = createAsyncThunk(
    "orderSlice/changeSingleOrder",
    async function ({ data, id }, { rejectWithValue, dispatch }) {
        try {
            let response = await api.put(`/order/order/${id}/`, data)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const findOrderByPhone = createAsyncThunk(
    "orderSlice/findOrderByPhone",
    async function (findOrder, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("/client/client/find_client/", findOrder)
            if (response.status === 200 || response.status === 201) {
                return dispatch(orderItem({ ...response.data }))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


// filtered api 
export const getOrders = createAsyncThunk(
    "orderSlice/getOrders",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                dispatch(setOrderList({ ...response }))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getSingleOrder = createAsyncThunk(
    "orderSlice/getSingleOrder",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(setSingleOrder(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const order = createSlice({
    name: "orderSlice",
    initialState: {
        newOrder: {
            phone_number: null,
            first_name: null,
            last_name: null,
            main_phone_number: null,
            address: null,
            client: null,
            found: null,
            district: null,
            status: null,
            error: null,
        },
        orderList: {
            orders: [],
            total_price: null,
            paid_money: null,
            count: null,
            status: null,
            error: null
        },
        singleOrder: {
            order: null,
            status: null,
            error: null
        },
        changedOrder: {
            status: null,
            error: null
        },
        orderFiles: {
            status: null,
            error: null
        },
        cancelOrder: {
            status: null,
            error: null
        }
    },
    reducers: {
        orderItem: (state, action) => {
            state.newOrder.phone_number = action.payload.phone_number
            state.newOrder.first_name = action.payload.first_name
            state.newOrder.last_name = action.payload.last_name
            state.newOrder.main_phone_number = action.payload.main_phone_number
            state.newOrder.address = action.payload.address
            state.newOrder.found = action.payload.found
            state.newOrder.district = action.payload.district

            state.newOrder.client = action.payload.id
        },
        setOrderList: (state, action) => {
            state.orderList.orders = action.payload.data.orders
            state.orderList.total_price = action.payload.data.total_price
            state.orderList.paid_money = action.payload.data.paid_money
            state.orderList.status = action.payload.status
            state.orderList.count = action.payload.data.count
        },
        resetOrderItem: (state) => {
            state.newOrder.phone_number = null
            state.newOrder.first_name = null
            state.newOrder.last_name = null
            state.newOrder.main_phone_number = null
            state.newOrder.address = null
            state.newOrder.client = null
        },
        setSingleOrder: (state, action) => {
            state.singleOrder.order = action.payload
        },
        clearSingleOrder: (state, action) => {
            state.singleOrder.order = null
        }
    },
    extraReducers: {
        [postOrder.pending]: (state) => {
            state.newOrder.status = 'pending'
            state.newOrder.error = null
        },
        [postOrder.fulfilled]: (state, action) => {
            state.newOrder.status = "resolved"
        },
        [postOrder.rejected]: (state, action) => {
            state.newOrder.status = "rejected";
            state.newOrder.error = action.payload;
        },

        [sendOrderFiles.pending]: (state) => {
            state.orderFiles.status = 'pending'
            state.orderFiles.error = null
        },
        [sendOrderFiles.fulfilled]: (state) => {
            state.orderFiles.status = 'resolved'
        },
        [sendOrderFiles.rejected]: (state, action) => {
            state.orderFiles.status = 'rejected'
            state.orderFiles.error = action.payload
        },

        [findOrderByPhone.pending]: (state) => {
            state.newOrder.status = 'pending'
            state.newOrder.error = null
        },
        [findOrderByPhone.fulfilled]: (state, action) => {
            state.newOrder.status = "resolved"
        },
        [findOrderByPhone.rejected]: (state, action) => {
            state.newOrder.status = "rejected";
            state.newOrder.error = action.payload;
        },

        [getOrders.pending]: (state) => {
            state.orderList.status = 'pending'
            state.orderList.error = null
        },
        [getOrders.fulfilled]: (state, action) => {
            state.orderList.status = "resolved"
        },
        [getOrders.rejected]: (state, action) => {
            state.orderList.status = "rejected";
            state.orderList.error = action.payload;
        },
        [getSingleOrder.pending]: (state) => {
            state.singleOrder.status = "pending"
            state.singleOrder.error = null
        },
        [getSingleOrder.fulfilled]: (state, action) => {
            state.singleOrder.status = "resolved"
        },
        [getSingleOrder.rejected]: (state, action) => {
            state.singleOrder.status = "rejected";
            state.singleOrder.error = action.payload;
        },
        [changeSingleOrder.pending]: (state) => {
            state.changedOrder.status = "pending"
            state.changedOrder.error = null
        },
        [changeSingleOrder.fulfilled]: (state, action) => {
            state.changedOrder.status = "resolved"
        },
        [changeSingleOrder.rejected]: (state, action) => {
            state.changedOrder.status = "rejected";
            state.changedOrder.error = action.payload;
        },

        [cancelOrder.pending]: (state) => {
            state.cancelOrder.status = "pending"
            state.cancelOrder.error = null
        },
        [cancelOrder.fulfilled]: (state, action) => {
            state.cancelOrder.status = "resolved"
        },
        [cancelOrder.rejected]: (state, action) => {
            state.cancelOrder.status = "rejected";
            state.cancelOrder.error = action.payload;
        },

    }
})

export const { resetOrderItem, orderItem, setOrderList, setSingleOrder, clearSingleOrder } = order.actions

export default order.reducer


