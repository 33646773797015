import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";
import axios from "axios";

export const get_warehouse_productStorage = createAsyncThunk(
  "productSlice/get_warehouse_productStorage",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_productStorage(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_profillength = createAsyncThunk(
  "profillength/get_warehouse_profillength",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        // console.log(response.data);
        dispatch(set_productLenght(response.data));
        // return response.data;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_productType = createAsyncThunk(
  "productSlice/get_warehouse_productStorage",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_productType(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_profiles = createAsyncThunk(
  "productSlice/get_warehouse_profiles",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_profiles(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_accessory = createAsyncThunk(
  "productSlice/get_warehouse_accessory",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_accessory(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_profile_accessory = createAsyncThunk(
  "productSlice/get_warehouse_profile_accessory",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_profile_accessory(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_goods_release_profil_detail = createAsyncThunk(
  "productSlice/get_warehouse_goods_release_profil_detail",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_goods_release_profil_detail(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_goods_release_accessory_detail = createAsyncThunk(
  "productSlice/get_warehouse_goods_release_accessory_detail",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_goods_release_accessory_detail(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_acceptance_history = createAsyncThunk(
  "productSlice/get_warehouse_acceptance_history",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_acceptance_history(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_supplier_acceptance = createAsyncThunk(
  "productSlice/get_warehouse_supplier_acceptance",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_supplier_acceptance(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_supplier_orderDetail = createAsyncThunk(
  "productSlice/get_warehouse_supplier_orderDetail",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);

      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_supplier_orderDetail(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_warehouse_profileType = createAsyncThunk(
  "productSlice/get_warehouse_profileType",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await axios.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_warehouse_profileType(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const post_warehouse_productLength = createAsyncThunk(
  "productSlice/post_warehouse_productStorage",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      let response = await api.post("/warehouse/profillength/", data);
      console.log(`response`, response);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const post_warehouse_productStorage = createAsyncThunk(
  "productSlice/post_warehouse_productStorage",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      let response = await api.post("/warehouse/profillength/", data);
      console.log(`response`, response);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const edit_warehouse_productStorage = createAsyncThunk(
  "productSlice/edit_warehouse_productStorage",
  async function (obj, { rejectWithValue, dispatch }) {
    try {
      let response = await api.put(
        `/warehouse/product/create_get/${obj.id}/`,
        obj.data
      );
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const edit_warehouse_productLenght = createAsyncThunk(
  "productSlice/edit_warehouse_productLenght",
  async function (obj, { rejectWithValue, dispatch }) {
    try {
      let response = await api.put(
        `/warehouse/profillength/${obj.id}/`,
        obj.data
      );
      console.log(`response`, response);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const get_single_productStorage = createAsyncThunk(
  "productSlice/get_single_productStorage",
  async function (id, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/warehouse/product/detail/${id}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(set_single_productStorage(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const delete_single_productStorage = createAsyncThunk(
  "productSlice/delete_single_productStorage",
  async function (id, { rejectWithValue, dispatch }) {
    try {
      let response = await api.delete(`/warehouse/product/detail/${id}`);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const warehouse_products = createSlice({
  name: "residueSlice",
  initialState: {
    productStorage: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    productLenght: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    warehouse_goods_release_profil_detail: {
      data: {},
      status: null,
      error: null,
    },
    warehouse_goods_release_accessory_detail: {
      data: {},
      status: null,
      error: null,
    },
    productType: {
      data: [],
      status: null,
      error: null,
    },
    warehouse_profile_accessory: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    warehouse_acceptance_history: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    warehouse_supplier_acceptance: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    warehouse_supplier_orderDetail: {
      data: [],
      status: null,
      count: null,
      error: null,
    },
    single_productStorage: {
      data: null,
      status: null,
      error: null,
    },
    single_productLenght: {
      data: null,
      status: null,
      error: null,
    },
    delete_single_productStorage: {
      status: null,
      error: null,
    },
    addProductStorage: {
      status: null,
      error: null,
    },
    editProductStorage: {
      status: null,
      error: null,
    },
    // length
    addProductLength: {
      status: null,
      error: null,
    },
    editProductLength: {
      status: null,
      error: null,
    },

    warehouse_profiles: {
      data: [],
      status: null,
      error: null,
    },
    warehouse_accessory: {
      data: [],
      status: null,
      error: null,
    },
    warehouse_profileType: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    set_productStorage: (state, action) => {
      state.productStorage.data = action.payload.results || action.payload;
      state.productStorage.count = action.payload.count;
    },
    set_productLenght: (state, action) => {
      state.productLenght.data = action.payload;
      state.productLenght.count = action.payload.lenght + 1;
    },
    // single_productLenght
    set_single_product: (state, action) => {
      state.single_productLenght.data = action.payload;
    },
    set_warehouse_goods_release_profil_detail: (state, action) => {
      state.warehouse_goods_release_profil_detail.data = action.payload;
    },
    set_warehouse_goods_release_accessory_detail: (state, action) => {
      state.warehouse_goods_release_accessory_detail.data = action.payload;
    },
    set_productType: (state, action) => {
      state.productType.data = action.payload;
    },
    set_single_productStorage: (state, action) => {
      state.single_productStorage.data = action.payload;
    },
    set_warehouse_profile_accessory: (state, action) => {
      state.warehouse_profile_accessory.data = action.payload;
    },
    set_warehouse_supplier_acceptance: (state, action) => {
      state.warehouse_supplier_acceptance.data = action.payload;
    },
    set_warehouse_acceptance_history: (state, action) => {
      state.warehouse_acceptance_history.data = action.payload;
    },
    set_warehouse_supplier_orderDetail: (state, action) => {
      state.warehouse_supplier_orderDetail.data = action.payload;
    },
    clear_single_productStorage: (state, action) => {
      state.single_productStorage.data = null;
      state.single_productStorage.error = null;
      state.single_productStorage.status = null;
    },
    set_warehouse_profiles: (state, action) => {
      state.warehouse_profiles.data = action.payload;
    },
    set_warehouse_accessory: (state, action) => {
      state.warehouse_accessory.data = action.payload;
    },
    set_warehouse_profileType: (state, action) => {
      state.warehouse_profileType.data = action.payload;
    },
  },
  extraReducers: {
    [get_warehouse_productStorage.pending]: (state) => {
      state.productStorage.status = "pending";
      state.productStorage.error = null;
    },
    [get_warehouse_productStorage.fulfilled]: (state, action) => {
      state.productStorage.status = "resolved";
      state.productStorage.data = action.payload;
    },
    [get_warehouse_productStorage.rejected]: (state, action) => {
      state.productStorage.status = "rejected";
      state.productStorage.error = action.payload;
    },

    [get_warehouse_profillength.pending]: (state) => {
      state.productLenght.status = "pending";
      state.productLenght.error = null;
    },
    [get_warehouse_profillength.fulfilled]: (state, action) => {
      state.productLenght.status = "resolved";
    },
    [get_warehouse_profillength.rejected]: (state, action) => {
      state.productLenght.status = "rejected";
      state.productLenght.error = action.payload;
    },

    [get_warehouse_supplier_acceptance.pending]: (state) => {
      state.warehouse_supplier_acceptance.status = "pending";
      state.warehouse_supplier_acceptance.error = null;
    },
    [get_warehouse_supplier_acceptance.fulfilled]: (state, action) => {
      state.warehouse_supplier_acceptance.status = "resolved";
    },
    [get_warehouse_supplier_acceptance.rejected]: (state, action) => {
      state.warehouse_supplier_acceptance.status = "rejected";
      state.warehouse_supplier_acceptance.error = action.payload;
    },
    [get_warehouse_supplier_orderDetail.pending]: (state) => {
      state.warehouse_supplier_orderDetail.status = "pending";
      state.warehouse_supplier_orderDetail.error = null;
    },
    [get_warehouse_supplier_orderDetail.fulfilled]: (state, action) => {
      state.warehouse_supplier_orderDetail.status = "resolved";
    },
    [get_warehouse_supplier_orderDetail.rejected]: (state, action) => {
      state.warehouse_supplier_orderDetail.status = "rejected";
      state.warehouse_supplier_orderDetail.error = action.payload;
    },
    // *************************
    [post_warehouse_productLength.pending]: (state) => {
      state.addProductLength.status = "pending";
      state.addProductLength.error = null;
    },
    [post_warehouse_productLength.fulfilled]: (state, action) => {
      state.addProductLength.status = "resolved";
    },
    [post_warehouse_productLength.rejected]: (state, action) => {
      state.addProductLength.status = "rejected";
      state.addProductLength.error = action.payload;
    },

    [post_warehouse_productStorage.pending]: (state) => {
      state.addProductLength.status = "pending";
      state.addProductLength.error = null;
    },
    [post_warehouse_productStorage.fulfilled]: (state, action) => {
      state.addProductLength.status = "resolved";
    },
    [post_warehouse_productStorage.rejected]: (state, action) => {
      state.addProductLength.status = "rejected";
      state.addProductLength.error = action.payload;
    },

    // ***********************************

    [get_warehouse_profiles.pending]: (state) => {
      state.warehouse_profiles.status = "pending";
      state.warehouse_profiles.error = null;
    },
    [get_warehouse_profiles.fulfilled]: (state, action) => {
      state.warehouse_profiles.status = "resolved";
    },
    [get_warehouse_profiles.rejected]: (state, action) => {
      state.warehouse_profiles.status = "rejected";
      state.warehouse_profiles.error = action.payload;
    },
    [get_warehouse_acceptance_history.pending]: (state) => {
      state.warehouse_acceptance_history.status = "pending";
      state.warehouse_acceptance_history.error = null;
    },
    [get_warehouse_acceptance_history.fulfilled]: (state, action) => {
      state.warehouse_acceptance_history.status = "resolved";
    },
    [get_warehouse_acceptance_history.rejected]: (state, action) => {
      state.warehouse_acceptance_history.status = "rejected";
      state.warehouse_acceptance_history.error = action.payload;
    },
    [get_warehouse_accessory.pending]: (state) => {
      state.warehouse_accessory.status = "pending";
      state.warehouse_accessory.error = null;
    },
    [get_warehouse_accessory.fulfilled]: (state, action) => {
      state.warehouse_accessory.status = "resolved";
    },
    [get_warehouse_accessory.rejected]: (state, action) => {
      state.warehouse_accessory.status = "rejected";
      state.warehouse_accessory.error = action.payload;
    },

    [get_warehouse_profile_accessory.pending]: (state) => {
      state.warehouse_profile_accessory.status = "pending";
      state.warehouse_profile_accessory.error = null;
    },
    [get_warehouse_profile_accessory.fulfilled]: (state, action) => {
      state.warehouse_profile_accessory.status = "resolved";
    },
    [get_warehouse_profile_accessory.rejected]: (state, action) => {
      state.warehouse_profile_accessory.status = "rejected";
      state.warehouse_profile_accessory.error = action.payload;
    },

    [get_warehouse_profileType.pending]: (state) => {
      state.warehouse_profileType.status = "pending";
      state.warehouse_profileType.error = null;
    },
    [get_warehouse_profileType.fulfilled]: (state, action) => {
      state.warehouse_profileType.status = "resolved";
    },
    [get_warehouse_profileType.rejected]: (state, action) => {
      state.warehouse_profileType.status = "rejected";
      state.warehouse_profileType.error = action.payload;
    },

    // ***********************
    [edit_warehouse_productStorage.pending]: (state) => {
      state.editProductStorage.status = "pending";
      state.editProductStorage.error = null;
    },
    [edit_warehouse_productStorage.fulfilled]: (state, action) => {
      state.editProductStorage.status = "resolved";
    },
    [edit_warehouse_productStorage.rejected]: (state, action) => {
      state.editProductStorage.status = "rejected";
      state.editProductStorage.error = action.payload;
    },

    [edit_warehouse_productLenght.pending]: (state) => {
      state.editProductLength.status = "pending";
      state.editProductLength.error = null;
    },
    [edit_warehouse_productLenght.fulfilled]: (state, action) => {
      state.editProductLength.status = "resolved";
    },
    [edit_warehouse_productLenght.rejected]: (state, action) => {
      state.editProductLength.status = "rejected";
      state.editProductLength.error = action.payload;
    },
    // ****************
    [get_single_productStorage.pending]: (state) => {
      state.single_productStorage.status = "pending";
      state.single_productStorage.error = null;
    },
    [get_single_productStorage.fulfilled]: (state, action) => {
      state.single_productStorage.status = "resolved";
    },
    [get_single_productStorage.rejected]: (state, action) => {
      state.single_productStorage.status = "rejected";
      state.single_productStorage.error = action.payload;
    },

    [delete_single_productStorage.pending]: (state) => {
      state.delete_single_productStorage.status = "pending";
      state.delete_single_productStorage.error = null;
    },
    [delete_single_productStorage.fulfilled]: (state, action) => {
      state.delete_single_productStorage.status = "resolved";
    },
    [delete_single_productStorage.rejected]: (state, action) => {
      state.delete_single_productStorage.status = "rejected";
      state.delete_single_productStorage.error = action.payload;
    },

    [get_warehouse_productType.pending]: (state) => {
      state.delete_single_productStorage.status = "pending";
      state.delete_single_productStorage.error = null;
    },
    [get_warehouse_productType.fulfilled]: (state, action) => {
      state.delete_single_productStorage.status = "resolved";
    },
    [get_warehouse_productType.rejected]: (state, action) => {
      state.delete_single_productStorage.status = "rejected";
      state.delete_single_productStorage.error = action.payload;
    },
  },
});

export const {
  set_productStorage,
  set_productLenght,
  set_single_product,
  set_warehouse_accessory,
  set_warehouse_profiles,
  set_warehouse_profileType,
  set_single_productStorage,
  set_warehouse_profile_accessory,
  set_warehouse_supplier_acceptance,
  set_warehouse_supplier_orderDetail,
  set_warehouse_acceptance_history,
  set_productType,
  set_warehouse_goods_release_profil_detail,
  set_warehouse_goods_release_accessory_detail,
  clear_single_productStorage,
} = warehouse_products.actions;

export default warehouse_products.reducer;
