import React,{lazy} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux"
import {store} from "./redux/store"
import './index.css';
// import App from './App';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Suspense } from 'react';
import Loader from './components/Suspense_Loader/Loader';

const App = lazy(()=>import("./App"))


const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
    <Suspense fallback={<Loader/>}>
    <App />
    </Suspense>
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
);
