import React from 'react'
import "./Loader.css"
// import gif from "../../images/Global/gif.gif"
import gif from "../../images/Global/logo-akfa.svg"
import { Triangle } from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Loader = () => {
  return (
    <div className="body-loader">

      <Triangle
        height="200"
        width="200"
        color="#4fa94d"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      {/* <img src={gif} alt="" /> */}
      {/* <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
    </div>
  )
}

export default Loader



