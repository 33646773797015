import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const get_finance_dashboard = createAsyncThunk(
    "dashboardSlice/get_finance_dashboard",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                return dispatch(set_dashboard_info(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)


export const dashboard = createSlice({
    name: "dashboardSlice",
    initialState: {
        dashboard_info: {
            overall_sum: 0,
            paid_money: 0,
            need_paid: 0,
            akfa_yuklari: 0,
            marketing_price: 0,
            oylik_price: 0,
            logisika_price: 0,
            total: 0,
            boshqa_harajatlar_price: 0,
            status: null,
            error: null
        },

    },
    reducers: {
        set_dashboard_info: (state, action) => {
            state.dashboard_info.overall_sum = action.payload.overall_sum
            state.dashboard_info.paid_money = action.payload.paid_money
            state.dashboard_info.total = action.payload.total
            state.dashboard_info.need_paid = action.payload.need_paid
            state.dashboard_info.akfa_yuklari = action.payload.akfa_yuklari
            state.dashboard_info.marketing_price = action.payload.marketing_price
            state.dashboard_info.oylik_price = action.payload.oylik_price
            state.dashboard_info.logisika_price = action.payload.logisika_price
            state.dashboard_info.boshqa_harajatlar_price = action.payload.boshqa_harajatlar_price
        },
    },
    extraReducers: {
        [get_finance_dashboard.pending]: (state) => {
            state.dashboard_info.status = 'pending'
            state.dashboard_info.error = null
        },
        [get_finance_dashboard.fulfilled]: (state, action) => {
            state.dashboard_info.status = "resolved"
        },
        [get_finance_dashboard.rejected]: (state, action) => {
            state.dashboard_info.status = "rejected";
            state.dashboard_info.error = action.payload;
        },
    }
})

const { set_dashboard_info } = dashboard.actions

export default dashboard.reducer