import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";

export const getDefects = createAsyncThunk(
    "mainSlice/getDefects",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_defects(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getDefectDetails = createAsyncThunk(
    "mainSlice/getDefectDetails",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_defect_details(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getDefectProductionNames = createAsyncThunk(
    "mainSlice/getDefectProductionNames",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_defect_names(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const postNewDefect = createAsyncThunk(
    "mainSlice/postNewDefect",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const main = createSlice({
    name:"mainSlice",
    initialState:{
       defects:{
         data:[],
         error:null,
         status:null,
         count:1
       },
       defect_details:{
         data:[],
         error:null,
         status:null
       },
       new_defect:{
        error:null,
        status:null
       },
       defect_names:{
        error:null,
        status:null,
        data:[]
       }
    },
    reducers:{
      set_defects:(state,action)=>{
        state.defects.data = action.payload.results
        state.defects.count = action.payload.count

      },
      set_defect_details:(state,action)=>{
        state.defect_details.data = action.payload
      },
      set_defect_names:(state,action)=>{
        state.defect_names.data = action.payload.results
      }
      
    },
    extraReducers:{
        [getDefects.pending]:(state)=>{
            state.defects.status = 'pending'
            state.defects.error = null
         },
         [getDefects.fulfilled]:(state,action)=>{
            state.defects.status = "resolved"
         }, 
         [getDefects.rejected]:(state,action)=>{
           state.defects.status = "rejected";
           state.defects.error = action.payload;
         },
         [getDefectDetails.pending]:(state)=>{
            state.defect_details.status = 'pending'
            state.defect_details.error = null
         },
         [getDefectDetails.fulfilled]:(state,action)=>{
            state.defect_details.status = "resolved"
         }, 
         [getDefectDetails.rejected]:(state,action)=>{
           state.defect_details.status = "rejected";
           state.defect_details.error = action.payload;
         },
         [postNewDefect.pending]:(state)=>{
            state.new_defect.status = 'pending'
            state.new_defect.error = null
         },
         [postNewDefect.fulfilled]:(state,action)=>{
            state.new_defect.status = "resolved"
         }, 
         [postNewDefect.rejected]:(state,action)=>{
           state.new_defect.status = "rejected";
           state.new_defect.error = action.payload;
         },
    }
})

export const {set_defects,set_defect_details,set_defect_names}  = main.actions

export default main.reducer


