import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const get_tailoring_orders = createAsyncThunk(
    "tailoring/get_tailoring_orders",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_orders(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const get_tailoring_profiles = createAsyncThunk(
    "tailoring/get_tailoring_orders",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_profiles(response))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const get_tailoring_orders_details = createAsyncThunk(
    "tailoring/get_tailoring_orders_detail",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_orders_details(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const get_tailoring_orders_calculate = createAsyncThunk(
    "tailoring/get_tailoring_orders_calculate",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_orders_calculate(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const postTailoringFile = createAsyncThunk(
    "tailoringSlice/postTailoringFile",
    async function (data, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("order/order/create/file/", data)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const get_tailoring_storage = createAsyncThunk(
    "tailoring/get_tailoring_storage",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_storage(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const get_tailoring_provision = createAsyncThunk(
    "tailoring/get_tailoring_provision",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                dispatch(set_tailoring_provision(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const tailoring = createSlice({
    name: "tailoringSlice",
    initialState: {
        tailoring_profile: {
            profil: [],
            status: null,
            error: null
        },
        tailoring_order: {
            orders: [],
            count: 1,
            status: null,
            error: null
        },
        tailoring_storage: {
            storage: [],
            status: null,
            error: null
        },
        tailoring_provision: {
            provision: [],
            status: null,
            error: null
        },
        tailoring_order_details: {
            details: null,
            count: 1,
            status: null,
            error: null
        },
        tailoring_order_calculate: {
            data: null,
            status: null,
            error: null
        },
        tailoring_file: {
            status: null,
            error: null
        }
    },
    reducers: {
        set_tailoring_profiles: (state, action) => {
            state.tailoring_profile.profil = action.payload.data
            state.tailoring_profile.status = action.payload.status
        },
        set_tailoring_orders: (state, action) => {
            state.tailoring_order.orders = action.payload.results
            state.tailoring_order.count = action.payload.count
        },
        set_tailoring_orders_details: (state, action) => {
            state.tailoring_order_details.details = action.payload
        },
        set_tailoring_orders_calculate: (state, action) => {
            state.tailoring_order_calculate.data = action.payload
        },
        set_tailoring_storage: (state, action) => {
            state.tailoring_storage.storage = action.payload
        },
        set_tailoring_provision: (state, action) => {
            state.tailoring_provision.provision = action.payload
        }
    },
    extraReducers: {
        [get_tailoring_orders.pending]: (state) => {
            state.tailoring_order.status = 'pending'
            state.tailoring_order.error = null
        },
        [get_tailoring_orders.fulfilled]: (state, action) => {
            state.tailoring_order.status = "resolved"
        },
        [get_tailoring_orders.rejected]: (state, action) => {
            state.tailoring_order.status = "rejected";
            state.tailoring_order.error = action.payload;
        },
        [get_tailoring_storage.pending]: (state) => {
            state.tailoring_storage.status = 'pending'
            state.tailoring_storage.error = null
        },
        [get_tailoring_storage.fulfilled]: (state, action) => {
            state.tailoring_storage.status = "resolved"
        },
        [get_tailoring_storage.rejected]: (state, action) => {
            state.tailoring_storage.status = "rejected";
            state.tailoring_storage.error = action.payload;
        },
        [get_tailoring_provision.pending]: (state) => {
            state.tailoring_provision.status = 'pending'
            state.tailoring_provision.error = null
        },
        [get_tailoring_provision.fulfilled]: (state, action) => {
            state.tailoring_provision.status = "resolved"
        },
        [get_tailoring_provision.rejected]: (state, action) => {
            state.tailoring_provision.status = "rejected";
            state.tailoring_provision.error = action.payload;
        },
        [get_tailoring_orders_details.pending]: (state) => {
            state.tailoring_order_details.status = 'pending'
            state.tailoring_order_details.error = null
        },
        [get_tailoring_orders_details.fulfilled]: (state, action) => {
            state.tailoring_order_details.status = "resolved"
        },
        [get_tailoring_orders_details.rejected]: (state, action) => {
            state.tailoring_order_details.status = "rejected";
            state.tailoring_order_details.error = action.payload;
        },
        [get_tailoring_orders_calculate.pending]: (state) => {
            state.tailoring_order_calculate.status = 'pending'
            state.tailoring_order_calculate.error = null
        },
        [get_tailoring_orders_calculate.fulfilled]: (state, action) => {
            state.tailoring_order_calculate.status = "resolved"
        },
        [get_tailoring_orders_calculate.rejected]: (state, action) => {
            state.tailoring_order_calculate.status = "rejected";
            state.tailoring_order_calculate.error = action.payload;
        },
        [postTailoringFile.pending]: (state) => {
            state.tailoring_file.status = 'pending'
            state.tailoring_file.error = null
        },
        [postTailoringFile.fulfilled]: (state, action) => {
            state.tailoring_file.status = "resolved"
        },
        [postTailoringFile.rejected]: (state, action) => {
            state.tailoring_file.status = "rejected";
            state.tailoring_file.error = action.payload;
        }
    }
})

export const {
    set_tailoring_orders,
    set_tailoring_storage,
    set_tailoring_orders_details,
    set_tailoring_provision,
    set_tailoring_orders_calculate,
    set_tailoring_profiles
} = tailoring.actions

export default tailoring.reducer
