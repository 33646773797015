import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_director_productList = createAsyncThunk(
    "storageSlice/get_director_productList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            
            if (response.status === 200 || response.status === 201){
                dispatch(set_director_productList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)




export const storage = createSlice({
    name:"storageSlice",
    initialState:{
        product_list:{
            data:[],
            status:null,
            error:null,
            count:1
        },
    },
    reducers:{
        set_director_productList:(state, action)=>{
            state.product_list.data = action.payload.results
            state.product_list.count = action.payload.count
        },
    
    },
    extraReducers:{
        [get_director_productList.pending]:(state)=>{
           state.product_list.status = 'pending'
           state.product_list.error = null
        },
        [get_director_productList.fulfilled]:(state,action)=>{
           state.product_list.status = "resolved"
        }, 
        [get_director_productList.rejected]:(state,action)=>{
          state.product_list.status = "rejected";
          state.product_list.error = action.payload;
        }
    }
})

export const {set_director_productList}  = storage.actions

export default storage.reducer


