import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_director_expenseList = createAsyncThunk(
    "expenseSlice/get_director_expenseList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_director_expenseList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)




export const expense = createSlice({
    name:"expenseSlice",
    initialState:{
        expense_list:{
            data:[],
            status:null,
            error:null,
            count:1
        },
    },
    reducers:{
        set_director_expenseList:(state, action)=>{
            state.expense_list.data = action.payload.results
            state.expense_list.count = action.payload.count
        },
    
    },
    extraReducers:{
        [get_director_expenseList.pending]:(state)=>{
           state.expense_list.status = 'pending'
           state.expense_list.error = null
        },
        [get_director_expenseList.fulfilled]:(state,action)=>{
           state.expense_list.status = "resolved"
        }, 
        [get_director_expenseList.rejected]:(state,action)=>{
          state.expense_list.status = "rejected";
          state.expense_list.error = action.payload;
        }
    }
})

export const {set_director_expenseList}  = expense.actions

export default expense.reducer


