import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";


export const postCurrency = createAsyncThunk(
    "currencySlice/postCurrency",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getCurrencyList = createAsyncThunk(
    "currencySlice/getCurrencyList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(setCurrencyList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getCurrencyCard = createAsyncThunk(
    "currencySlice/getCurrencyCard",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(setCurrencyCard(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const currency = createSlice({
    name:"currencySlice",
    initialState:{
        newCurrency:{
          status:null,
          error:null,
        },
        currencyList:{
            data:[],
            count:null,
            status:null,
            error:null
        },
        currencyCard:{
            dollar_price:0,
            count:0,
            uzs_price:0,
            status:null,
            error:null
        }
    },
    reducers:{
        setCurrencyList:(state, action)=>{
            state.currencyList.data = action.payload.results
            state.currencyList.count = action.payload.count
        },
        setCurrencyCard:(state, action)=>{
            state.currencyCard.dollar_price = action.payload.dollar_price
            state.currencyCard.count = action.payload.count
            state.currencyCard.uzs_price = action.payload.uzs_price
        }
    },
    extraReducers:{
        [postCurrency.pending]:(state)=>{
            state.newCurrency.status = 'pending'
            state.newCurrency.error = null
        },
        [postCurrency.fulfilled]:(state,action)=>{
            state.newCurrency.status = "resolved"
        }, 
        [postCurrency.rejected]:(state,action)=>{
           state.newCurrency.status = "rejected";
           state.newCurrency.error = action.payload;
        },

        [getCurrencyList.pending]:(state)=>{
           state.currencyList.status = 'pending'
           state.currencyList.error = null
        },
        [getCurrencyList.fulfilled]:(state,action)=>{
           state.currencyList.status = "resolved"
        }, 
        [getCurrencyList.rejected]:(state,action)=>{
          state.currencyList.status = "rejected";
          state.currencyList.error = action.payload;
        },
        [getCurrencyCard.pending]:(state)=>{
            state.currencyCard.status = 'pending'
            state.currencyCard.error = null
         },
         [getCurrencyCard.fulfilled]:(state,action)=>{
            state.currencyCard.status = "resolved"
         }, 
         [getCurrencyCard.rejected]:(state,action)=>{
           state.currencyCard.status = "rejected";
           state.currencyCard.error = action.payload;
         },
    }
})

export const {setCurrencyList,setCurrencyCard}  = currency.actions

export default currency.reducer


