import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";

export const getDashboardInfo = createAsyncThunk(
    "dashboardSlice/getDashboardInfo",
    async function (data, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(`/show_room/payment/dashboard/${data ?? ""}`)
            if (response.status === 200 || response.status === 201) {
                return dispatch(setDashBoardInfo(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)

export const getPaymentInfo = createAsyncThunk(
    "dashboardSlice/getPaymentInfo",
    async function (_, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get("/show_room/payment/price_statistics/")
            if (response.status === 200 || response.status === 201) {
                return dispatch(setPaymentInfo(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)

export const getOrderStatistics = createAsyncThunk(
    "dashboardSlice/getOrderStatistics",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                return dispatch(setOrderStatisticsInfo(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }

)

export const dashboard = createSlice({
    name: "dashboardSlice",
    initialState: {
        dashboardInfo: {
            all_clients: 0,
            all_orders: 0,
            finished_orders: 0,
            total_price: 0,
            paid_price: 0,
            residue_price: 0,
            status: null,
            error: null
        },
        paymentInfo: {
            paid_price: 0,
            total_price: 0,
            payment_must_be_made: 0,
            status: null,
            error: null
        },
        orderStatisticsInfo: {
            all: 0,
            approved: 0,
            reviewed: 0,
            status: null,
            error: null
        }
    },
    reducers: {
        setDashBoardInfo: (state, action) => {
            state.dashboardInfo.all_clients = action.payload.all_clients
            state.dashboardInfo.all_orders = action.payload.all_orders
            state.dashboardInfo.finished_orders = action.payload.finished_orders
            state.dashboardInfo.total_price = action.payload.total_price
            state.dashboardInfo.paid_price = action.payload.paid_price
            state.dashboardInfo.residue_price = action.payload.residue_price

        },
        setPaymentInfo: (state, action) => {
            state.paymentInfo.paid_price = action.payload.paid_price
            state.paymentInfo.total_price = action.payload.total_price
            state.paymentInfo.payment_must_be_made = action.payload.payment_must_be_made
        },
        setOrderStatisticsInfo: (state, action) => {
            state.orderStatisticsInfo.all = action.payload.all
            state.orderStatisticsInfo.approved = action.payload.approved
            state.orderStatisticsInfo.reviewed = action.payload.reviewed
        },
    },
    extraReducers: {
        [getDashboardInfo.pending]: (state) => {
            state.dashboardInfo.status = 'pending'
            state.dashboardInfo.error = null
        },
        [getDashboardInfo.fulfilled]: (state, action) => {
            state.dashboardInfo.status = "resolved"
        },
        [getDashboardInfo.rejected]: (state, action) => {
            state.dashboardInfo.status = "rejected";
            state.dashboardInfo.error = action.payload;
        },
        [getPaymentInfo.pending]: (state) => {
            state.paymentInfo.status = 'pending'
            state.paymentInfo.error = null
        },
        [getPaymentInfo.fulfilled]: (state, action) => {
            state.paymentInfo.status = "resolved"
        },
        [getPaymentInfo.rejected]: (state, action) => {
            state.paymentInfo.status = "rejected";
            state.paymentInfo.error = action.payload;
        },
        [getOrderStatistics.pending]: (state) => {
            state.orderStatisticsInfo.status = 'pending'
            state.orderStatisticsInfo.error = null
        },
        [getOrderStatistics.fulfilled]: (state, action) => {
            state.orderStatisticsInfo.status = "resolved"
        },
        [getOrderStatistics.rejected]: (state, action) => {
            state.orderStatisticsInfo.status = "rejected";
            state.orderStatisticsInfo.error = action.payload;
        },
    }
})

const { setPaymentInfo, setDashBoardInfo, setOrderStatisticsInfo } = dashboard.actions

export default dashboard.reducer