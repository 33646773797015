import {configureStore} from "@reduxjs/toolkit"
import users from "./login/user"
//showroom
import orders from "./showroom/order"
import newCustomer from "./showroom/customer"
import payment from "./showroom/payment"
import dashboard from "./showroom/dashboard"
//warehouse
import tailoring from "./warehouse/tailoring"
import  warehouse_residues  from "./warehouse/residue"
import  warehouse_products  from "./warehouse/products"
//sales
import sales from "./sales/main"
//production
import production_groupAssign from "./Production/groupAssign"
import production_orderRemainId from "./Production/orderRemainId"
import production_defects from "./Production/defects"
import production_finishedProjects from "./Production/FinishedProjects"
import production_window from "./Production/window"
//window
import window from "./Window/window"
//director
import director_dashboard from "./director/dashboard"
import director_income from "./director/income"
import director_expense from "./director/expense"
import director_residue_storage from "./director/residue_storage"
import director_storage_sale from "./director/storage_sale"
import director_showroom_window from "./director/showroom_window"
//finance
import finance_dashboard from "./Finance/dashboard"
import finance_expenses from "./Finance/expense"
import finance_incomes from "./Finance/income"
import finance_orders from "./Finance/orders"
import finance_currency from "./Finance/currency"

export const store = configureStore({
    reducer:{
        login:users,
        
        showroom_orders:orders,
        newCustomer,
        payment,
        dashboard,
        
        tailoring,
        warehouse_residues,
        warehouse_products,
        
        sales,
        
        production_groupAssign,
        production_orderRemainId,
        production_defects,
        production_finishedProjects,
        production_window,
        
        window,

        director_dashboard,
        director_income,
        director_residue_storage,
        director_storage_sale,
        director_showroom_window,
        director_expense,

        finance_dashboard,
        finance_expenses,
        finance_incomes,
        finance_orders,
        finance_currency

    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
})