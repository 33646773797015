import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";


export const postIncome = createAsyncThunk(
    "incomeSlice/postIncome",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getIncomes = createAsyncThunk(
    "incomeSlice/getIncomes",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(setIncomeList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getIncomeTypes = createAsyncThunk(
    "incomeSlice/getIncomeTypes",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(setIncomeTypes(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const income = createSlice({
    name:"incomeSlice",
    initialState:{
        newIncome:{
          status:null,
          error:null,
        },
        incomeTypes:{
            data:[],
            status:null,
            error:null,
          },
        incomeList:{
            incomes:[],
            count:null,
            status:null,
            error:null
        },
    },
    reducers:{
        setIncomeList:(state, action)=>{
            state.incomeList.incomes = action.payload.results
            state.incomeList.count = action.payload.count
        },
        setIncomeTypes:(state, action)=>{
            state.incomeTypes.data = action.payload
        }
       
    },
    extraReducers:{
        [postIncome.pending]:(state)=>{
            state.newIncome.status = 'pending'
            state.newIncome.error = null
        },
        [postIncome.fulfilled]:(state,action)=>{
            state.newIncome.status = "resolved"
        }, 
        [postIncome.rejected]:(state,action)=>{
           state.newIncome.status = "rejected";
           state.newIncome.error = action.payload;
        },

        [getIncomes.pending]:(state)=>{
           state.incomeList.status = 'pending'
           state.incomeList.error = null
        },
        [getIncomes.fulfilled]:(state,action)=>{
           state.incomeList.status = "resolved"
        }, 
        [getIncomes.rejected]:(state,action)=>{
          state.incomeList.status = "rejected";
          state.incomeList.error = action.payload;
        },
        [getIncomeTypes.pending]:(state)=>{
            state.incomeTypes.status = 'pending'
            state.incomeTypes.error = null
         },
         [getIncomeTypes.fulfilled]:(state,action)=>{
            state.incomeTypes.status = "resolved"
         }, 
         [getIncomeTypes.rejected]:(state,action)=>{
           state.incomeTypes.status = "rejected";
           state.incomeTypes.error = action.payload;
         },
    }
})

export const {setIncomeList,setIncomeTypes}  = income.actions

export default income.reducer


