import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API_URL = "https://akfabackend.backoffice.uz/api/v1/";
// const API_URL = "http://backendzulhimmat.backoffice.uz/api/v1";
// const API_URL = "http://138.68.97.55/api/v1";
// const API_URL = "https://backend.tezkorakfa.uz/api/v1";
// const API_URL = "https://backend.tezkorakfa.uz/api/v1";



const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Authorization: `Token ${cookies.get("access")}`
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api