import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";

export const getAllFinishedProjects = createAsyncThunk(
    "mainSlice/getAllFinishedProjects",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_finished_projects(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getSingleProjectDetail = createAsyncThunk(
    "mainSlice/getSingleProjectDetail",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_single_project_details(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const putSingleProjectStatus = createAsyncThunk(
    "mainSlice/putSingleProjectStatus",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.put(url)
            if (response.status === 200 || response.status === 201){
                return
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const main = createSlice({
    name:"mainSlice",
    initialState:{
       finished_projects:{
         data:[],
         error:null,
         status:null,
         count:1
       },
       single_project_details:{
         data:[],
         error:null,
         status:null
       },
       single_project_status:{
        error:null,
        status:null
      }
     
    },
    reducers:{
      set_finished_projects:(state,action)=>{
        state.finished_projects.data = action.payload.results
        state.finished_projects.count = action.payload.count

      },
      set_single_project_details:(state,action)=>{
        state.single_project_details.data = action.payload
      },
      
    },
    extraReducers:{
        [getAllFinishedProjects.pending]:(state)=>{
            state.finished_projects.status = 'pending'
            state.finished_projects.error = null
         },
         [getAllFinishedProjects.fulfilled]:(state,action)=>{
            state.finished_projects.status = "resolved"
         }, 
         [getAllFinishedProjects.rejected]:(state,action)=>{
           state.finished_projects.status = "rejected";
           state.finished_projects.error = action.payload;
         },
         [getSingleProjectDetail.pending]:(state)=>{
            state.single_project_details.status = 'pending'
            state.single_project_details.error = null
         },
         [getSingleProjectDetail.fulfilled]:(state,action)=>{
            state.single_project_details.status = "resolved"
         }, 
         [getSingleProjectDetail.rejected]:(state,action)=>{
           state.single_project_details.status = "rejected";
           state.single_project_details.error = action.payload;
         },
         [putSingleProjectStatus.pending]:(state)=>{
            state.single_project_status.status = 'pending'
            state.single_project_status.error = null
         },
         [putSingleProjectStatus.fulfilled]:(state,action)=>{
            state.single_project_status.status = "resolved"
         }, 
         [putSingleProjectStatus.rejected]:(state,action)=>{
           state.single_project_status.status = "rejected";
           state.single_project_status.error = action.payload;
         },
         
    }
})

export const {set_finished_projects,set_single_project_details}  = main.actions

export default main.reducer


