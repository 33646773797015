import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";

export const getOrders = createAsyncThunk(
    "mainSlice/getOrders",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_orders(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getProductionMembers = createAsyncThunk(
    "mainSlice/getProductionMembers",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_production_members(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getOrderDetails = createAsyncThunk(
    "mainSlice/getOrderDetails",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_order_details(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const assignToGroup = createAsyncThunk(
    "mainSlice/assignToGroup",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.put(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getGroupSquare = createAsyncThunk(
    "mainSlice/getGroupSquare",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_group_square(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getOrdersHistory = createAsyncThunk(
    "mainSlice/getOrdersHistory",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                 
                return dispatch(set_orders_history(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getGroupList = createAsyncThunk(
    "mainSlice/getGroupList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                 
                return dispatch(set_group_list(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getSingleGroup = createAsyncThunk(
    "mainSlice/getSingleGroup",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                console.log(response.data)
                return dispatch(set_single_group(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const createGroup = createAsyncThunk(
    "mainSlice/createGroup",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                 
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getNewGroupList = createAsyncThunk(
    "mainSlice/getNewGroupList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_new_groupList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)




export const main = createSlice({
    name:"mainSlice",
    initialState:{
       orders:{
         data:[],
         count:1,
         error:null,
         status:null
       },
       single_group:{
        data:[],
        error:null,
        status:null
       },
       new_group_list:{
        data:[],
        error:null,
        status:null
       },
       group_list:{
         data:[],
         error:null,
         status:null
       },
       new_group:{
        status:null,
        error:null
       },
       orders_history:{
        data:[],
        error:null,
        status:null,
        count:1
      },
       order_details:{
         data:null,
         error:null,
         status:null
       },
       group_square:{
         data:[],
         count:1,
         error:null,
         status:null
       },
       group_assign:{
        error:null,
        status:null
      },
      production_members:{
        error:null,
        status:null,
        data:[]
      }
    },
    reducers:{
      set_orders:(state,action)=>{
        state.orders.data = action.payload.results
        state.orders.count = action.payload.count
      },
      set_new_groupList:(state,action)=>{
        state.new_group_list.data = action.payload.results
      },
      set_production_members:(state,action)=>{
        state.production_members.data = action.payload
      },
      set_single_group:(state,action)=>{
        state.single_group.data = action.payload
      },
      set_group_list:(state,action)=>{
        state.group_list.data = action.payload.results
        state.group_list.count = action.payload.count

      },
      set_order_details:(state,action)=>{
        state.order_details.data = action.payload
      },
      set_group_square:(state,action)=>{
        state.group_square.data = action.payload.results
        state.group_square.count = action.payload.count

      },
      set_orders_history:(state,action)=>{
        state.orders_history.data = action.payload.results
        state.orders_history.count = action.payload.count

      }
    },
    extraReducers:{
        [getOrders.pending]:(state)=>{
            state.orders.status = 'pending'
            state.orders.error = null
         },
         [getOrders.fulfilled]:(state,action)=>{
            state.orders.status = "resolved"
         }, 
         [getOrders.rejected]:(state,action)=>{
           state.orders.status = "rejected";
           state.orders.error = action.payload;
         },
         [getOrderDetails.pending]:(state)=>{
            state.order_details.status = 'pending'
            state.order_details.error = null
         },
         [getOrderDetails.fulfilled]:(state,action)=>{
            state.order_details.status = "resolved"
         }, 
         [getOrderDetails.rejected]:(state,action)=>{
           state.order_details.status = "rejected";
           state.order_details.error = action.payload;
         },
         [assignToGroup.pending]:(state)=>{
            state.group_assign.status = 'pending'
            state.group_assign.error = null
         },
         [assignToGroup.fulfilled]:(state,action)=>{
            state.group_assign.status = "resolved"
         }, 
         [assignToGroup.rejected]:(state,action)=>{
           state.group_assign.status = "rejected";
           state.group_assign.error = action.payload;
         },
         [getGroupSquare.pending]:(state)=>{
            state.group_square.status = 'pending'
            state.group_square.error = null
         },
         [getGroupSquare.fulfilled]:(state,action)=>{
            state.group_square.status = "resolved"
         }, 
         [getGroupSquare.rejected]:(state,action)=>{
           state.group_square.status = "rejected";
           state.group_square.error = action.payload;
         },
         [getOrdersHistory.pending]:(state)=>{
            state.orders_history.status = 'pending'
            state.orders_history.error = null
         },
         [getOrdersHistory.fulfilled]:(state,action)=>{
            state.orders_history.status = "resolved"
         }, 
         [getOrdersHistory.rejected]:(state,action)=>{
           state.orders_history.status = "rejected";
           state.orders_history.error = action.payload;
         },
         [getGroupList.pending]:(state)=>{
            state.group_list.status = 'pending'
            state.group_list.error = null
         },
         [getGroupList.fulfilled]:(state,action)=>{
            state.group_list.status = "resolved"
         }, 
         [getGroupList.rejected]:(state,action)=>{
           state.group_list.status = "rejected";
           state.group_list.error = action.payload;
         },
         [createGroup.pending]:(state)=>{
            state.new_group.status = 'pending'
            state.new_group.error = null
         },
         [createGroup.fulfilled]:(state,action)=>{
            state.new_group.status = "resolved"
         }, 
         [createGroup.rejected]:(state,action)=>{
           state.new_group.status = "rejected";
           state.new_group.error = action.payload;
         },
         [getSingleGroup.pending]:(state)=>{
            state.single_group.status = 'pending'
            state.single_group.error = null
         },
         [getSingleGroup.fulfilled]:(state,action)=>{
            state.single_group.status = "resolved"
         }, 
         [getSingleGroup.rejected]:(state,action)=>{
           state.single_group.status = "rejected";
           state.single_group.error = action.payload;
         },
         [getProductionMembers.pending]:(state)=>{
            state.production_members.status = 'pending'
            state.production_members.error = null
         },
         [getProductionMembers.fulfilled]:(state,action)=>{
            state.production_members.status = "resolved"
         }, 
         [getProductionMembers.rejected]:(state,action)=>{
           state.production_members.status = "rejected";
           state.production_members.error = action.payload;
         },
         [getNewGroupList.pending]:(state)=>{
            state.new_group_list.status = 'pending'
            state.new_group_list.error = null
         },
         [getNewGroupList.fulfilled]:(state,action)=>{
            state.new_group_list.status = "resolved"
         }, 
         [getNewGroupList.rejected]:(state,action)=>{
           state.new_group_list.status = "rejected";
           state.new_group_list.error = action.payload;
         }

    }
})

export const {set_new_groupList,set_production_members,set_single_group,set_group_list,set_orders,set_order_details,set_group_square,set_orders_history}  = main.actions

export default main.reducer


