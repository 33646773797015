import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";


export const postCustomer = createAsyncThunk(
    "customerSlice/postCustomer",
    async function (postNewCustomer, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("/client/client/", postNewCustomer)
            if (response.status === 200 || response.status === 201) {
                return { postNewCustomer }
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return { postNewCustomer }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)




export const getCustomers = createAsyncThunk(
    "customerSlice/getCustomers",
    async function (page, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(page)
            if (response.status === 200 || response.status === 201) {
                dispatch(setClients(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getSingleCustomer = createAsyncThunk(
    "customerSlice/getSingleCustomer",
    async function (id, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(`/client/client/${id}/`)
            if (response.status === 200 || response.status === 201) {
                dispatch(setSingleClient(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const changeSingleCustomer = createAsyncThunk(
    "customerSlice/changeSingleCustomer",
    async function ({ data, id }, { rejectWithValue, dispatch }) {
        try {
            let response = await api.put(`/client/client/${id}/`, data)
            if (response.status === 200 || response.status === 201) {
                return
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const getRegions = createAsyncThunk(
    "customerSlice/getRegions",
    async function (_, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get("/show_room/district/")
            if (response.status === 200 || response.status === 201) {
                dispatch(setRegions(response.data.results))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const findCustomerByPhone = createAsyncThunk(
    "customerSlice/findCustomerByPhone",
    async function (findCustomer, { rejectWithValue, dispatch }) {
        try {
            let response = await api.post("/client/client/find_client/", findCustomer)
            if (response.status === 200 || response.status === 201) {
                return dispatch(clientHandle(response.data))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


export const customer = createSlice({
    name: "customerSlice",
    initialState: {
        clientExist: {
            first_name: null,
            last_name: null,
            address: null,
            main_phone_number: null,
            phone_number: null,
            status: null,
            error: null,
        },
        regions: {
            district: [],
            status: null,
            error: null
        },
        newClient: {
            status: null,
            error: null
        },
        clientList: {
            clients: [],
            count: null,
            status: null,
            error: null
        },
        singleClient: {
            data: null,
            status: null,
            error: null
        },
    },
    reducers: {
        clientHandle: (state, action) => {
            state.clientExist.first_name = action.payload.first_name
            state.clientExist.last_name = action.payload.last_name
            state.clientExist.address = action.payload.address
            state.clientExist.main_phone_number = action.payload.main_phone_number
            state.clientExist.phone_number = action.payload.phone_number
        },
        resetClient: (state, action) => {
            state.clientExist.first_name = null
            state.clientExist.last_name = null
            state.clientExist.address = null
            state.clientExist.main_phone_number = null
            state.clientExist.phone_number = null
            state.clientExist.status = null
            state.clientExist.error = null
        },
        setRegions: (state, action) => {
            state.regions.district = action.payload
        },
        setClients: (state, action) => {
            state.clientList.clients = action.payload.results
            state.clientList.count = action.payload.count
        },
        setSingleClient: (state, action) => {
            state.singleClient.data = action.payload
        },
        clearSingleClient: (state) => {
            state.singleClient.data = null
        },

    },
    extraReducers: {
        [findCustomerByPhone.pending]: (state) => {
            state.clientExist.status = 'pending'
            state.clientExist.error = null
        },
        [findCustomerByPhone.fulfilled]: (state, action) => {
            state.clientExist.status = "resolved"
        },
        [findCustomerByPhone.rejected]: (state, action) => {
            state.clientExist.status = "rejected";
            state.clientExist.error = action.payload;
        },
        [getRegions.pending]: (state) => {
            state.regions.status = 'pending'
            state.regions.error = null
        },
        [getRegions.fulfilled]: (state, action) => {
            state.regions.status = "resolved"
        },
        [getRegions.rejected]: (state, action) => {
            state.regions.status = "rejected";
            state.regions.error = action.payload;
        },
        [postCustomer.pending]: (state) => {
            state.newClient.status = 'pending'
            state.newClient.error = null
        },
        [postCustomer.fulfilled]: (state, action) => {
            state.newClient.status = "resolved"
        },
        [postCustomer.rejected]: (state, action) => {
            state.newClient.status = "rejected";
            state.newClient.error = action.payload;
        },
        [getCustomers.pending]: (state) => {
            state.clientList.status = 'pending'
            state.clientList.error = null
        },
        [getCustomers.fulfilled]: (state, action) => {
            state.clientList.status = "resolved"
        },
        [getCustomers.rejected]: (state, action) => {
            state.clientList.status = "rejected";
            state.clientList.error = action.payload;
        },
        [getSingleCustomer.pending]: (state) => {
            state.singleClient.status = 'pending'
            state.singleClient.error = null
        },
        [getSingleCustomer.fulfilled]: (state, action) => {
            state.singleClient.status = "resolved"
        },
        [getSingleCustomer.rejected]: (state, action) => {
            state.singleClient.status = "rejected";
            state.singleClient.error = action.payload;
        },
        [changeSingleCustomer.pending]: (state) => {
            state.singleClient.status = 'pending'
            state.singleClient.error = null
        },
        [changeSingleCustomer.fulfilled]: (state, action) => {
            state.singleClient.status = "resolved"
        },
        [changeSingleCustomer.rejected]: (state, action) => {
            state.singleClient.status = "rejected";
            state.singleClient.error = action.payload;
        },

    }
})

export const { clientHandle, resetClient, setRegions, newClient, setClients, setSingleClient, clearSingleClient } = customer.actions

export default customer.reducer
