import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_finance_orderList = createAsyncThunk(
    "orderSlice/get_finance_orderList",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_finance_orderList(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const get_finance_orderPrice = createAsyncThunk(
    "orderSlice/get_finance_orderPrice",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_finance_orderPrice(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const get_finance_showroom_districts = createAsyncThunk(
    "orderSlice/get_finance_showroom_districts",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                dispatch(set_finance_districts(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const order = createSlice({
    name:"orderSlice",
    initialState:{
        order_list:{
            data:[],
            status:null,
            total_summa:null,
            error:null,
            residue_total:null,
            paid_total:null,
            count:1
        },
        order_price:{
            data:null,
            status:null,
            error:null,
        },
        districts:{
            data:[],
            status:null,
            error:null,
        },
    },
    reducers:{
        set_finance_orderList:(state, action)=>{
            state.order_list.data = action.payload.results
            state.order_list.total_summa = action.payload.total_summa
            state.order_list.residue_total = action.payload.residue_total
            state.order_list.paid_total = action.payload.paid_total
            state.order_list.count = action.payload.count
        },
        set_finance_orderPrice:(state, action)=>{
            state.order_price.data = action.payload
        },
        set_finance_districts:(state, action)=>{
            state.districts.data = action.payload
        }
    
    },
    extraReducers:{
        [get_finance_orderList.pending]:(state)=>{
           state.order_list.status = 'pending'
           state.order_list.error = null
        },
        [get_finance_orderList.fulfilled]:(state,action)=>{
           state.order_list.status = "resolved"
        }, 
        [get_finance_orderList.rejected]:(state,action)=>{
          state.order_list.status = "rejected";
          state.order_list.error = action.payload;
        },
        [get_finance_orderPrice.pending]:(state)=>{
            state.order_price.status = 'pending'
            state.order_price.error = null
         },
         [get_finance_orderPrice.fulfilled]:(state,action)=>{
            state.order_price.status = "resolved"
         }, 
         [get_finance_orderPrice.rejected]:(state,action)=>{
           state.order_price.status = "rejected";
           state.order_price.error = action.payload;
         },
         [get_finance_showroom_districts.pending]:(state)=>{
            state.districts.status = 'pending'
            state.districts.error = null
         },
         [get_finance_showroom_districts.fulfilled]:(state,action)=>{
            state.districts.status = "resolved"
         }, 
         [get_finance_showroom_districts.rejected]:(state,action)=>{
           state.districts.status = "rejected";
           state.districts.error = action.payload;
         }
    }
})

export const {set_finance_orderList,set_finance_orderPrice,set_finance_districts}  = order.actions

export default order.reducer


