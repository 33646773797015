import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../api/api";



export const get_director_showroomWindow = createAsyncThunk(
    "showroom_windowSlice/get_director_showroomWindow",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                dispatch(set_director_showroomWindow({ ...response.data }))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



export const get_director_showroomPrice = createAsyncThunk(
    "showroom_windowSlice/get_director_showroomPrice",
    async function (url, { rejectWithValue, dispatch }) {
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201) {
                console.log(response);
                dispatch(set_director_showroomPrice({ ...response.data }))
            }
            if (!response.status) {
                throw new Error("Internal Server Error")
            }
            return
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)



export const showroom_windows = createSlice({
    name: "showroom_windowSlice",
    initialState: {
        showroom_window: {
            data: [],
            paid_money: null,
            total_price: null,
            clients_count: null,
            orders_paid_count: null,
            status: null,
            count: 1,
            error: null
        },
        showroom_price: {
            data: null,
            status: null,
            error: null
        },
    },
    reducers: {
        set_director_showroomWindow: (state, action) => {
            state.showroom_window.data = action.payload.orders
            state.showroom_window.paid_money = action.payload.paid_money
            state.showroom_window.clients_count = action.payload.clients_count
            state.showroom_window.orders_paid_count = action.payload.orders_paid_count
            state.showroom_window.total_price = action.payload.total_price
            state.showroom_window.count = action.payload.count

        },
        set_director_showroomPrice: (state, action) => {
            state.showroom_price.data = action.payload
        }

    },
    extraReducers: {
        [get_director_showroomWindow.pending]: (state) => {
            state.showroom_window.status = 'pending'
            state.showroom_window.error = null
        },
        [get_director_showroomWindow.fulfilled]: (state, action) => {
            state.showroom_window.status = "resolved"
        },
        [get_director_showroomWindow.rejected]: (state, action) => {
            state.showroom_window.status = "rejected";
            state.showroom_window.error = action.payload;
        },
        [get_director_showroomPrice.pending]: (state) => {
            state.showroom_price.status = 'pending'
            state.showroom_price.error = null
        },
        [get_director_showroomPrice.fulfilled]: (state, action) => {
            state.showroom_price.status = "resolved"
        },
        [get_director_showroomPrice.rejected]: (state, action) => {
            state.showroom_price.status = "rejected";
            state.showroom_price.error = action.payload;
        }
    }
})

export const { set_director_showroomWindow, set_director_showroomPrice } = showroom_windows.actions

export default showroom_windows.reducer


