import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";

export const getWindows = createAsyncThunk(
    "mainSlice/getWindows",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_windows(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const getSingleWindow = createAsyncThunk(
    "mainSlice/getSingleWindow",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                console.log(response.data)
                return dispatch(set_single_window(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)


export const getWindowOrders = createAsyncThunk(
    "mainSlice/getWindowOrders",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_window_orders(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const postNewWindow = createAsyncThunk(
    "mainSlice/postNewWindow",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await api.post(data.url,data.content)
            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const main = createSlice({
    name:"mainSlice",
    initialState:{
       windows:{
         data:[],
         error:null,
         status:null,
         count:1
       },
       window_orders:{
        data:[],
        count:1,
        error:null,
        status:null
       },
       new_window:{
        error:null,
        status:null
       },
       single_window:{
        data:[],
        error:null,
        status:null
       }
    },
    reducers:{
      set_windows:(state,action)=>{
        state.windows.data = action.payload.results
        state.windows.count = action.payload.count
      },
      set_window_orders:(state,action)=>{
        state.window_orders.data = action.payload.results
        state.window_orders.count = action.payload.count
      },
      set_single_window:(state,action)=>{
        state.single_window.data = action.payload
      },
    },
    extraReducers:{
        [getWindows.pending]:(state)=>{
            state.windows.status = 'pending'
            state.windows.error = null
         },
         [getWindows.fulfilled]:(state,action)=>{
            state.windows.status = "resolved"
         }, 
         [getWindows.rejected]:(state,action)=>{
           state.windows.status = "rejected";
           state.windows.error = action.payload;
         },
         [postNewWindow.pending]:(state)=>{
            state.new_window.status = 'pending'
            state.new_window.error = null
         },
         [postNewWindow.fulfilled]:(state,action)=>{
            state.new_window.status = "resolved"
         }, 
         [postNewWindow.rejected]:(state,action)=>{
           state.new_window.status = "rejected";
           state.new_window.error = action.payload;
         },
         [getSingleWindow.pending]:(state)=>{
            state.single_window.status = 'pending'
            state.single_window.error = null
         },
         [getSingleWindow.fulfilled]:(state,action)=>{
            state.single_window.status = "resolved"
         }, 
         [getSingleWindow.rejected]:(state,action)=>{
           state.single_window.status = "rejected";
           state.single_window.error = action.payload;
         },
         [getWindowOrders.pending]:(state)=>{
            state.window_orders.status = 'pending'
            state.window_orders.error = null
         },
         [getWindowOrders.fulfilled]:(state,action)=>{
            state.window_orders.status = "resolved"
         }, 
         [getWindowOrders.rejected]:(state,action)=>{
           state.window_orders.status = "rejected";
           state.window_orders.error = action.payload;
         },
         
    }
})

export const {set_windows,set_single_window,set_window_orders}  = main.actions

export default main.reducer


