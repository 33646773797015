import {createAsyncThunk,createSlice} from "@reduxjs/toolkit"
import api from "../../api/api";

export const getAllWindowOrders = createAsyncThunk(
    "mainSlice/getAllWindowOrders",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_all_window_orders(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const get_window_types = createAsyncThunk(
    "mainSlice/get_window_types",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_window_types(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)

export const edit_delete_window_order = createAsyncThunk(
    "mainSlice/edit_delete_window_order",
    async function (data,{rejectWithValue,dispatch}){
        try {
            let response = await data?.edit ? api.put(data.url,data.content):api.put(data.url)

            if (response.status === 200 || response.status === 201){
                return 
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {

            return rejectWithValue(error.message)
        }        
    }
)

export const getSingleWindowOrder = createAsyncThunk(
    "mainSlice/getSingleWindowOrder",
    async function (url,{rejectWithValue,dispatch}){
        try {
            let response = await api.get(url)
            if (response.status === 200 || response.status === 201){
                return dispatch(set_single_window_order(response.data))
            }
            if(!response.status){
                throw new Error("Internal Server Error")
            }
            return 
        } catch (error) {
            return rejectWithValue(error.message)
        }        
    }
)



export const main = createSlice({
    name:"mainSlice",
    initialState:{
       window_orders:{
         data:[],
         error:null,
         status:null,
         count:1
       },
       single_window_order:{
        data:null,
        error:null,
        status:null
       },
       window_types:{
        data:[],
        error:null,
        status:null
       },
       edit_window_order:{
        error:null,
        status:null
       }
    },
    reducers:{
     set_all_window_orders:(state,action)=>{
        state.window_orders.data = action.payload.results
        state.window_orders.count = action.payload.count
      },
     set_single_window_order:(state,action)=>{
        state.single_window_order.data = action.payload
      },
      set_window_types:(state,action)=>{
        state.window_types.data = action.payload
      },
    },
    extraReducers:{
        [getAllWindowOrders.pending]:(state)=>{
            state.window_orders.status = 'pending'
            state.window_orders.error = null
         },
         [getAllWindowOrders.fulfilled]:(state,action)=>{
            state.window_orders.status = "resolved"
         }, 
         [getAllWindowOrders.rejected]:(state,action)=>{
           state.window_orders.status = "rejected";
           state.window_orders.error = action.payload;
         },
         [get_window_types.pending]:(state)=>{
            state.window_types.status = 'pending'
            state.window_types.error = null
         },
         [get_window_types.fulfilled]:(state,action)=>{
            state.window_types.status = "resolved"
         }, 
         [get_window_types.rejected]:(state,action)=>{
           state.window_types.status = "rejected";
           state.window_types.error = action.payload;
         },
         [getSingleWindowOrder.pending]:(state)=>{
            state.single_window_order.status = 'pending'
            state.single_window_order.error = null
         },
         [getSingleWindowOrder.fulfilled]:(state,action)=>{
            state.single_window_order.status = "resolved"
         }, 
         [getSingleWindowOrder.rejected]:(state,action)=>{
           state.single_window_order.status = "rejected";
           state.single_window_order.error = action.payload;
         },
         [edit_delete_window_order.pending]:(state)=>{
            state.edit_window_order.status = 'pending'
            state.edit_window_order.error = null
         },
         [edit_delete_window_order.fulfilled]:(state,action)=>{
            state.edit_window_order.status = "resolved"
         }, 
         [edit_delete_window_order.rejected]:(state,action)=>{
           state.edit_window_order.status = "rejected";
           state.edit_window_order.error = action.payload;
         },
         
         
    }
})

export const {set_all_window_orders,set_single_window_order,set_window_types}  = main.actions

export default main.reducer


