import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const postOrderPrices = createAsyncThunk(
  "mainSlice/postOrderPrices",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      let response = await api.put(data.url, data.products);
      if (response.status === 200 || response.status === 201) {
        return dispatch(updatePrise2(...response.status));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getShowroomFile = createAsyncThunk(
  "mainSlice/getShowroomFile",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        return dispatch(setShowroomFile({ ...response.data }));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const patchShowroomFile = createAsyncThunk(
  "mainSlice/patchShowroomFile",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.patch(url);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProducts = createAsyncThunk(
  "mainSlice/getProducts",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(setProductList({ ...response.data }));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductsPrice = createAsyncThunk(
  "mainSlice/getProductsPrise",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(setProductPriceList({ ...response }));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const patchProductsPriceUpdate = createAsyncThunk(
  "mainSlice/patchProductsPriceUpdate",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      let response = await api.patch("/order/price/update/", data);
      if (response.status === 200 || response.status === 201) {
        return dispatch(updatePrise({ ...response.status }));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductTypes = createAsyncThunk(
  "mainSlice/getProductTypes",
  async function (url, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(url);
      if (response.status === 200 || response.status === 201) {
        dispatch(setProductTypes(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const putProducts = createAsyncThunk(
  "mainSlice/putProducts",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      let response = await api.put(data.url, data.products);
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const postPayment = createAsyncThunk(
  "mainSlice/putProducts",
  async function (initialstate, { rejectWithValue, dispatch }) {
    try {
      let response = await api.post(
        `/sales/payment/add_to_kassa/`,
        initialstate
      );
      if (response.status === 200 || response.status === 201) {
        return;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const main = createSlice({
  name: "mainSlice",
  initialState: {
    orderPrices: {
      error: null,
      status: null,
    },
    showRoomFile: {
      error: null,
      status: null,
      data: null,
    },
    products: {
      product_list: [],
      error: null,
      status: null,
      count: 1,
    },
    productPrice: {
      id: null,
      aldoks_price_door: null,
      aldoks_price_window: null,
      pvh_price_door: null,
      pvh_price_window: null,
      exclusive_price_door: null,
      exclusive_price_window: null,
      montage_price: null,
      others_price_door: null,
      others_price_window: null,
      error: null,
      status: null,
      count: 1,
    },
    productTypes: {
      types: [],
      error: null,
      status: null,
    },
    updatedProduct: {
      error: null,
      status: null,
    },
    updatedProduct2: {
      error: null,
      status: null,
    },
    items: [],
  },
  reducers: {
    setShowroomFile: (state, action) => {
      state.showRoomFile.data = action.payload;
    },
    setProductList: (state, action) => {
      state.products.product_list = action.payload.results;
      state.products.count = action.payload?.count;
    },
    setProductPriceList: (state, action) => {
      state.items = action.payload.data;
      state.productPrice.id = action.payload.data.id;
      state.productPrice.aldoks_price_door =
        action.payload.data.aldoks_price_door;
      state.productPrice.aldoks_price_window =
        action.payload.data.aldoks_price_window;
      state.productPrice.pvh_price_door = action.payload.data.pvh_price_door;
      state.productPrice.pvh_price_window =
        action.payload.data.pvh_price_window;
      state.productPrice.exclusive_price_door =
        action.payload.data.exclusive_price_door;
      state.productPrice.exclusive_price_window =
        action.payload.data.exclusive_price_window;
      state.productPrice.montage_price = action.payload.data.montage_price;
      state.productPrice.others_price_door =
        action.payload.data.others_price_door;
      state.productPrice.others_price_window =
        action.payload.data.others_price_window;
      state.productPrice.status = action.payload.status;
    },
    setProductTypes: (state, action) => {
      state.productTypes.types = action.payload;
    },
    updatePrise: (state, action) => {
      state.updatedProduct.status = action.payload.status;
    },
    updatePrise2: (state, action) => {
      state.updatedProduct2.status = action.payload.status;
    },
  },
  extraReducers: {
    [postOrderPrices.pending]: (state) => {
      state.orderPrices.status = "pending";
      state.orderPrices.error = null;
    },
    [postOrderPrices.fulfilled]: (state, action) => {
      state.orderPrices.status = "resolved";
    },
    [postOrderPrices.rejected]: (state, action) => {
      state.orderPrices.status = "rejected";
      state.orderPrices.error = action.payload;
    },

    [getProductTypes.pending]: (state) => {
      state.productTypes.status = "pending";
      state.productTypes.error = null;
    },
    [getProductTypes.fulfilled]: (state, action) => {
      state.productTypes.status = "resolved";
    },
    [getProductTypes.rejected]: (state, action) => {
      state.productTypes.status = "rejected";
      state.productTypes.error = action.payload;
    },

    [getShowroomFile.pending]: (state) => {
      state.showRoomFile.status = "pending";
      state.showRoomFile.error = null;
    },
    [getShowroomFile.fulfilled]: (state, action) => {
      state.showRoomFile.status = "resolved";
      state.showRoomFile.data = action.payload;
    },
    [getShowroomFile.rejected]: (state, action) => {
      state.showRoomFile.status = "rejected";
      state.showRoomFile.error = action.payload;
    },

    [getProducts.pending]: (state) => {
      state.products.status = "pending";
      state.products.error = null;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.products.status = "resolved";
    },
    [getProducts.rejected]: (state, action) => {
      state.cancelOrder.status = "rejected";
      state.cancelOrder.error = action.payload;
    },

    [putProducts.pending]: (state) => {
      state.updatedProduct.status = "pending";
      state.updatedProduct.error = null;
    },
    [putProducts.fulfilled]: (state, action) => {
      state.updatedProduct.status = "resolved";
    },
    [putProducts.rejected]: (state, action) => {
      state.updatedProduct.status = "rejected";
      state.updatedProduct.error = action.payload;
    },
  },
});

export const {
  setShowroomFile,
  setProductList,
  setProductTypes,
  setProductPriceList,
  updatePrise,
  updatePrise2,
} = main.actions;

export default main.reducer;
